<template>
  <div>
    <template v-if="merchant.shiftsOnSite.open">
      <div class="available" v-if="!merchant.shiftsOnSite.closeSoon">• {{ $t('header_open', {hours: merchant.shiftsOnSite.closeAt}) }}</div>
      <div class="close-soon" v-else>Ferme bientôt ({{merchant.shiftsOnSite.closeAt}})</div>
    </template>
    <template v-else>
      <div class="close-soon" v-if="merchant.shiftsOnSite.openSoon">Ouvre bientôt ({{merchant.shiftsOnSite.openHour}})</div>
      <div class="closed" v-else-if="merchant.shiftsOnSite.openToday">Ouvre à {{merchant.shiftsOnSite.openHour}}</div>
      <div class="closed" v-else-if="merchant.shiftsOnSite.openTomorrow">Ouvre demain à {{merchant.shiftsOnSite.openHour}}</div>
      <div class="closed" v-else-if="merchant.shiftsOnSite.openDateAt">Ouvre {{merchant.shiftsOnSite.openDateAt}}</div>
      <div class="closed" v-else-if="!merchant.shiftsOnSite.hasHours">Pas d'horaires enregistré</div>
    </template>
  </div>
</template>


<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {}
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters(['merchant'])
  }
}
</script>
